<template>
  <v-row justify="space-between">
    <v-col cols="12">
      <div class="d-flex align-center justify-end">
        <v-btn
          v-if="!hideCancelButton"
          class="mr-2"
          elevation="1"
          color="grey"
          @click="$emit('cancel')"
          ><span class="font-12 white--text">{{ cancelText }}</span></v-btn
        >
        <v-btn
          v-if="text"
          class="ml-2"
          elevation="1"
          color="green"
          @click="$emit('next')"
          :disabled="!isValid"
          ><span class="font-12 white--text">{{ text }}</span></v-btn
        >
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    isValid: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: "Next"
    },
    cancelText: {
      type: String,
      default: "Cancel"
    },
    hideCancelButton: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss"></style>
