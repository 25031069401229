<template>
  <div class="scrapers pa-2">
    <base-select
      v-model="selectedDistrict"
      :items="districts"
      placeholder="District"
      itemText="name"
      @input="districtDidChange"
    ></base-select>
    <base-select
      v-model="selectedVillage"
      :items="villages"
      placeholder="Village"
      itemText="name"
      @input="villageDidChange"
    ></base-select>
    <input
      class="mb-10"
      type="file"
      @change="handleFileUpload"
      accept=".json"
    />
    <buttons-experience
      @next="submit"
      text="Submit"
      :isValid="valid"
      :key="valid"
    ></buttons-experience>
    <v-data-table
      :headers="headers"
      :items="[{}, {}, {}, {}]"
      class="users-table"
      height="calc(100vh - 60px - 70px)"
      mobile-breakpoint="0"
      v-if="loading"
    >
      <template v-slot:item>
        <tr>
          <td v-for="(item, i) in jsonData" :key="i">
            <v-skeleton-loader type="text"></v-skeleton-loader>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-data-table
      v-if="!loading"
      :headers="headers"
      :items="jsonData"
      item-key="_id"
      class="users-table"
      height="calc(100vh - 60px - 70px)"
      items-per-page.sync="-1"
      mobile-breakpoint="0"
      fixed-header
    />
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  components: {
    ButtonsExperience
  },
  data: () => ({
    villages: [],
    villagePageTokens: [""],
    districts: [],
    categories: [],
    selectedDistrict: undefined,
    selectedVillage: undefined,
    jsonData: undefined,
    headers: [
      { text: "Name (Arabic)", value: "name.ar" },
      { text: "Name (English)", value: "name.en" },
      { text: "Header (Arabic)", value: "header.ar" },
      { text: "Header (English)", value: "header.en" },
      { text: "Body (Arabic)", value: "body.ar" },
      { text: "Body (English)", value: "body.en" },
      { text: "Longitude", value: "location.long" },
      { text: "Latitude", value: "location.lat" },
      { text: "Address", value: "location.address" },
      { text: "Phone", value: "contact.phone" },
      { text: "Website", value: "contact.website" },
      { text: "Email", value: "contact.email" },
      { text: "Instagram", value: "social.instagram" },
      { text: "Category", value: "category.name" },
      { text: "Season", value: "season" }
    ],
    loading: true,
    valid: false
  }),
  methods: {
    async districtDidChange() {},
    async villageDidChange() {},
    async fetchData() {
      let { data } = await this.$axios.get("activity/all/selectors");
      if (data) {
        this.districts = data.districts;
        if (this.districts.length > 0) {
          this.selectedDistrict = this.districts[0];
        }
        this.villages = data.villages;
        if (this.villages.length > 0) {
          this.selectedVillage = this.villages[0];
        }
      }
      let categoriesData = await this.$axios.get(`miniguide/category/dropdown`);
      if (categoriesData?.data) {
        this.categories = categoriesData.data.data;
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && file.type === "application/json") {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = JSON.parse(e.target.result);
            this.jsonData = data
              .map((data) => {
                const mappedCategory = this.categories.filter((cat) => {
                  const catName = cat.name.toLowerCase().replaceAll('"', "");
                  const dataCatName = data.category.name.en
                    .toLowerCase()
                    .replaceAll('"', "");
                  return catName == dataCatName;
                });
                if (mappedCategory && mappedCategory.length > 0) {
                  console.log(mappedCategory[0].name);
                  data.category = mappedCategory[0];
                  return data;
                } else {
                  return undefined;
                }
              })
              .filter((data) => data);
            this.valid = this.jsonData && this.jsonData.length > 0;
          } catch (error) {
            alert("Error parsing JSON file.");
          }
        };
        reader.readAsText(file);
        this.loading = false;
      } else {
        alert("Please select a valid JSON file.");
      }
    },
    submit() {
      const reqObj = this.jsonData.map((item) => {
        return {
          name: item.name,
          contact: {
            website: item.contact.website,
            phone: item.contact.phone,
            email: item.contact.email
          },
          social: {
            instagram: item.social.instagram,
            facebook: "",
            twitter: ""
          },
          sections: {
            restaurants: false
          },
          filters: [],
          location: {
            long: item.location.long,
            lat: item.location.lat,
            address: item.location.address,
            district: this.selectedDistrict
          },
          options: [],
          header: item.header,
          body: item.body,
          country: this.$root.country.short,
          village: this.selectedVillage,
          season: item.season,
          category: item.category,
          coverImage: item.coverImage,
          media: item.media
        };
      });

      reqObj.forEach(async (item) => {
        const cover = item.coverImage;
        const mediaImages = item.media;
        delete item.coverImage;
        delete item.media;
        let resCreate = await this.$axios.post(`miniguide/add/new`, item);
        // upload image
        if (cover.url) {
          const response = await fetch(cover.url);
          const coverImage = await response.blob();

          let formDataCover = new FormData();
          formDataCover.append("miniguideId", resCreate.data.data._id);
          formDataCover.append("coverImage", coverImage);
          let resAddCover = await this.$axios.post(
            `media/miniguide/cover`,
            formDataCover
          );
          if (resAddCover.data.data) {
            // request to upload media
            if (mediaImages && mediaImages.length > 0) {
              mediaImages.forEach(async (media, idx) => {
              if (media && media.url && media.url !== null) {
                const response = await fetch(media.url);
                const img = await response.blob();

                let formDataMedia = new FormData();
                formDataMedia.append("miniguideId", resCreate.data.data._id);
                formDataMedia.append("media", img);
                let resMedia = await this.$axios.post(
                  `media/miniguide`,
                  formDataMedia,
                  {
                    "content-type": "multipart/form-data"
                  }
                );
                if (resMedia.data) {
                  if (idx == media.length - 1) {
                    this.$store.dispatch("showSnack", {
                      text: "Added succesfully",
                      color: `success`
                    });
                  }
                }
              }
            });
            }
          }
        }
      });
    }
  },
  async created() {
    await this.fetchData();
  }
};
</script>

<style lang="scss" scoped>
.scrapers {
  background: white;
  height: 100vh;
  overflow: hidden;
  padding: 8;
}
.users-table {
  overflow: auto;
}
.tabs-items {
  overflow: hidden;
}
.tab-item {
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
}
</style>
